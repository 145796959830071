import {data} from "./types";
import katex from "katex";
import {Code} from "./code";
import {Card} from "./card";

export class JSXGenerator {

    text: string;
    html: any;
    type: string;
    latex: any[] = [];
    code: any[] = [];
    words: any[] = [];

    latexOn: boolean = false;
    LATEX_START: RegExp = /^\$/;
    LATEX_END: RegExp = /\$$/;
    LATEX_FULL: RegExp = /^\$.*\$$/;
    wordCount: number;
    codeOn: boolean = false;
    reviewType: string;
    card: Card;

    constructor(data: data, card: Card) {

        this.text = data.text;
        this.type = data.type;
        this.reviewType = card.reviewType;
        this.card = card;

        if (!((this.type === "image" || this.card.IMG_REGEX.test(this.text)) && this.reviewType === "back")) this._splitText();

        if (this.card.id === 4316) console.log(this.text, this.type, this.words)
        this.wordCount = this.words.length;
        this.html = this.toHTML();

    }

    _splitText( ) {
        let code: string[] = [];
        let latex: string[] = [];
        if (this.card.id === 4316) console.log(this.text)
        this.text.split(" ").forEach((word: string) => {
            word = word.trim();
            if (this.LATEX_FULL.test(word)) this.words.push(word);
            else if (this.LATEX_START.test(word)) {
                if (this.card.id === 4316) console.log(word)
                this.latexOn = true;
                latex.push(word);
            } else if (this.LATEX_END.test(word)) {
                this.latexOn = false;
                this.words.push([...latex, word].join(" "));
                latex = [];
            } else if (word.startsWith('`') && word.endsWith('`')) {
                this.words.push(word);
            } else if (word.startsWith('`')) {
                this.codeOn = true;
                code.push(word);
            } else if (word.endsWith('`')) {
                this.codeOn = false;
                this.words.push([...code, word].join(" "));
                code = [];
            } else if (this.latexOn) {
                latex.push(word);
            } else if (this.codeOn) {
                code.push(word);
            } else {
                this.words.push(word.trim());
            }}
        )
        if (latex.length > 0) this.words.push(latex.join(" "));
        if (code.length > 0) this.words.push(code.join(" "));
    }

    ClozePlaceholder = ({ }) => {
        return (
            <span>...</span>
        )
    }

    Word = ({ word }: any) => {
        return (
            <span>{word.includes("_") ? <this.ClozePlaceholder /> : word}</span>
        )
    }

    TextToJSX = () => {
        return (
            <div>
                {this.words.map((word: string, i: number) => <>{this._replace(word)}{i !== this.wordCount - 1 && <span>&nbsp;</span>}</>)}
            </div>
        )
    }

    OrderedListComponent = () => {
        return (
            <div className="spac-rep-ol">
                <ol>
                    {this.text.split(";").map((line: string) => <li>{line.split(" ").map((word: string) => <>{this._replace(word)}&nbsp;</>)}</li>)}
                </ol>
            </div>
        )
    }

    UnorderedListComponent = () => {
        return (
            <div className="spac-rep-ul">
                <ul>
                    {this.text.split(";").map((line: string) => <li>{line.split(" ").map((word: string) => <>{this._replace(word)}&nbsp;</>)}</li>)}
                </ul>
            </div>
        )
    }

    StandardComponent = () => {
        return (
            <div>
                <this.TextToJSX />
            </div>
        )
    }

    Image = () => {
        return (
            <div className='spacrep-img'>
                <img src={`/images/cards/${this.text}`} />
            </div>
        )
    }

    toHTML( ) {
        if (this.type === "code") {
            if (this.reviewType === "back") return (
                <div>
                    <div className="code-context-q">Which task does this code fragment perform?</div>
                    {new Code(this.text).toHMTL()}
                </div>)
            return new Code(this.text).toHMTL();
        }
        if (this.type === "list") return <this.OrderedListComponent />;
        if (this.type === "set" || this.text.includes(";")) return <this.UnorderedListComponent />;
        if (this.type === "image" || this.card.IMG_REGEX.test(this.text)) return <this.Image />;
        return <this.StandardComponent />;
    }

    LatexWord = ({ latex }: any) => {
        return (
            <span dangerouslySetInnerHTML={{__html: katex.renderToString(latex, {output: 'mathml'})}}></span>
        )
    }

    InlineCodeBlock = ({ code }: any) => {
        return (
            <code className="code-inline">
                {code.split("\n").map((line: string) => <div>{line}<br /></div>)}
            </code>
        )
    }

    _replace(word: string) {

        if ((word.startsWith("$") && word.endsWith("$"))) {
            return <this.LatexWord latex={word.replace(/\$/g, '')} />
        } else if (word.startsWith('`')) {
            return <this.InlineCodeBlock code={word.replace(/`/g, '')} />
        } else {
            return <this.Word word={word} />
        }

    }

}